@import url(https://fonts.googleapis.com/css?family=Sacramento);
*,
body,
html {
  font-family: "Inter", sans-serif;
}

.menu {
  width: 100%;
  padding: 0 20px;
  line-height: 63px;
  border: 0;
}
@media (max-width: 48em) {
  .menu {
    padding: 0;
  }
}
.app-header {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 8px #f0f0f0;
  padding: 0;
}
.app-header__logo-search-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.app-header__logo {
  display: inline-block;
  padding: 0 20px;
}
.app-header__logo img {
  width: 160px;
}

.app-header-links a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  color: #808080;
  margin: 0 16px;
}

.app-header__search-input {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
}
.app-header__search-input .ant-input-group {
  border: 0;
  width: 100%;
  max-width: 460px;
}
.app-header__search-input .ant-input-group input {
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.app-header__menu-section {
  float: right;
}
.app-header-skeleton {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 8px #f0f0f0;
  padding: 0;
}
.app-header-skeleton__search-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.app-header-skeleton__logo {
  padding: 0 20px;
}
.app-header-skeleton__logo img {
  width: 55px;
}
.app-header-skeleton__search-input {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
}
.app-header-skeleton__search-input .ant-input-group {
  border: 0;
  width: 100%;
  max-width: 460px;
}
.app-header-skeleton__search-input .ant-input-group input {
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}

.app-header_hamburger_icon {
  display: none;
}

.app-header_close_icon svg path {
  fill: #808080;
}

.app-header_mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 32px 32px 24px;
  position: absolute;
  width: 220px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 2px 8px #f0f1f2;
}

.app-header-links_mobile {
  display: flex;
  flex-direction: column;
}

.app-header-links_mobile a {
  color: #3b3b3b;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0px 8px;
  margin-bottom: 35px;
}

.error-banner {
  position: fixed;
  width: 100%;
  top: 64px;
  left: 0;
  z-index: 99;
}
.listing-card__cover-img {
  width: 100%;
  height: 195px;
  background-size: cover;
  background-position: 50%;
}
.listing-card__rating-tag {
  position: absolute;
  top: 5px;
  right: 0;
}
.listing-card__description {
  padding-bottom: 20px;
}
.listing-card__price.ant-typography {
  color: #1d226c;
}
.listing-card__price.ant-typography span {
  color: #c5c5c5;
  font-weight: 400;
}
.listing-card__title.ant-typography,
.listing-card__address.ant-typography {
  display: block;
}
.listing-card__dimensions i,
.listing-card__dimensions span {
  padding-right: 10px;
  font-size: 13px;
}
.listing-card__dimensions span {
  color: #c5c5c5;
}
.listing-card__dimensions--guests {
  float: right;
}
.listing-card__dimensions--guests span {
  padding-right: 8;
}
@media (max-width: 75em) {
  .listing-card__dimensions--guests {
    float: right;
    padding-bottom: 5px;
  }
}
@media (max-width: 48em) {
  .listing-card__dimensions--guests {
    float: right;
    padding-bottom: 0;
  }
}
.home-hero,
.page-skeleton__paragraph {
  padding-bottom: 40px;
}
.home-hero {
  width: 100%;
}
.home-hero__search {
  max-width: 600px;
}
.home-hero__title.ant-typography {
  color: #1d226c;
}
.home-hero__search-input input {
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.home-hero__cards {
  padding-top: 60px;
}
.home-hero__cards .ant-card {
  background: transparent;
  cursor: pointer;
}
.home-hero__cards .ant-card-cover:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1d226c;
  opacity: 0.5;
  transition: all 0.3s linear;
}
.home-hero__cards .ant-card-cover:hover:before {
  background: none;
}
.home-hero__cards .ant-card-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 0;
  color: #fff;
  font-weight: 700;
  position: relative;
  bottom: 40px;
}
.home-listings {
  padding: 40px 0 20px;
}
.home-listings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.home-listings-skeleton {
  padding: 40px 0 20px;
}
.home-listings-skeleton__card {
  margin: 5px 0;
}
.home-listings-skeleton__card-cover-img {
  width: 100%;
  height: 195px;
}
.home {
  padding: 60px 120px;
  /* background-image: url(/static/media/map-background.c21df147.jpg); */
  background-repeat: no-repeat;
  background-position: 10% 0;
}
@media (max-width: 75em) {
  .home {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.home__cta-section {
  padding: 40px 0;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}
.home__cta-section-title.ant-typography {
  color: #1d226c;
}
.home__cta-section-button {
  margin-top: 20px;
}
.home__listings {
  padding: 40px 0 20px;
}
.home__listings-title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.home__listings-img-cover {
  width: 100%;
  cursor: pointer;
}
@media (max-width: 36em) {
  .home__listings-img-cover {
    padding: 10px 0;
  }
}
.home__listings-img {
  width: 100%;
}
.host-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  max-width: 900px;
  margin: 0 auto;
}
@media (max-width: 62em) {
  .host-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.host__form-header {
  padding-bottom: 20px;
}

.host__form-title.ant-typography {
  color: #1d226c;
  margin-bottom: 5px;
}

.host__form-image-upload img {
  width: 125px;
}
.listing-bookings h3.ant-typography,
.listing-bookings h4.ant-typography {
  color: #1d226c;
}
.listing-bookings__section {
  font-size: 15px;
  padding: 5px 0;
}
.listing-bookings__item {
  margin: 20px 0;
}
.listing-bookings__history {
  margin-bottom: 20px;
}
.listing-bookings__history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.listing-bookings__stars {
  font-size: 13px;
}
.listing-booking {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}
@media (max-width: 62em) {
  .listing-booking {
    padding-top: 40px;
  }
}
.listing-booking__card-title.ant-typography {
  color: #1d226c;
  margin-bottom: 5px;
}
.listing-booking__card-title.ant-typography span {
  color: #c5c5c5;
  font-weight: 400;
}
.listing-booking__card {
  width: 100%;
  text-align: center;
}
.listing-booking__card-date-picker {
  padding-bottom: 20px;
}
.listing-booking__card-cta {
  display: block;
  margin: 0 auto 10px;
}
.ant-calendar-date__check-in {
  border: 1px solid #337ab7;
  border-radius: 50%;
}
.ant-calendar-footer-text {
  font-size: 11px;
  line-height: 20px;
  padding: 10px;
  display: block;
}
.listing-booking-modal {
  text-align: center;
  padding-top: 10px;
}
.listing-booking-modal__intro-title.ant-typography,
.listing-booking-modal__intro-title.ant-typography + h3.ant-typography {
  margin-top: 5px;
}
.listing-booking-modal__charge-summary-total {
  font-weight: 700;
  font-size: 15px;
}
.listing-booking-modal__charge-summary-stripe {
  display: block;
  font-size: 11px;
  padding-top: 5px;
}
.listing-booking-modal__stripe-card-section {
  padding-bottom: 10px;
}
.listing-booking-modal__stripe-card {
  padding: 10px;
  margin: 40px 0;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(29, 34, 108, 0.1);
}
.listing-booking-modal__cta {
  width: 100px;
}
.listing-details h3.ant-typography,
.listing-details h4.ant-typography {
  color: #1d226c;
}
.listing-details__image {
  width: 100%;
  height: 570px;
  background-size: cover;
  background-position: 50%;
  margin-bottom: 20px;
}
@media (max-width: 75em) {
  .listing-details__image {
    height: 380px;
  }
}
.listing-details__title.ant-typography {
  margin-top: 5px !important;
  margin-bottom: 5px;
}
.listing-details__title.ant-typography span {
  color: #c5c5c5;
  font-weight: 400;
}
.listing-details__city-address.ant-typography,
.listing-details__ratings.ant-typography {
  margin: 5px 0;
}
.listing-details__ratings.ant-typography {
  font-size: 13px;
}
.listing-details__host-name {
  font-family: "Inter", sans-serif;
  display: inline-block;
  padding-left: 10px;
  position: relative;
  top: 5px;
}
.listing-details__section {
  font-size: 15px;
  padding: 5px 0;
}
.listing-details__about-items {
  padding-bottom: 20px;
}
.listing-details__amenities-row {
  padding: 5px 0;
}
.listing-details__amenities-row i,
.listing-details__amenities-row span {
  padding: 0 2.5px;
}
.listing-details__booking-item {
  margin: 20px 0;
}
.listing-details__booking-history {
  margin-bottom: 20px;
}
.listing-details__booking-history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.listing-details__booking-stars {
  font-size: 13px;
}
.listing {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .listing {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.listings-filters {
  padding: 10px 0;
  background: #fff;
}
.listings-filters
  .ant-select-selection--single
  .ant-select-selection__rendered {
  margin-right: 30px;
}
.listings-filters span {
  padding-right: 5px;
}
.listings-pagination {
  padding: 10px 0;
  float: right;
}
@media (max-width: 48em) {
  .listings-pagination {
    float: none;
  }
}
.listings-skeleton__card {
  margin: 5px 0;
}
.listings-skeleton__card-cover-img {
  width: 100%;
  height: 195px;
}
.listings {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .listings {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.listings--none .ant-typography {
  font-size: 15px;
}
.listings__affix {
  background: #fff;
  padding: 10px 0;
}
.listings__title.ant-typography {
  color: #1d226c;
}
.log-in {
  display: flex;
  align-items: center;
  justify-content: center;
}
.log-in-card {
  width: 450px;
  text-align: center;
  padding: 10px 0 20px;
}
.log-in-card__intro-title.ant-typography,
.log-in-card__intro-title.ant-typography + h3.ant-typography {
  margin-top: 5px;
}
.log-in-card__google-button {
  margin: 40px auto;
  border-radius: 2px;
  /* background-color: #4285f4; */
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  /* border: none; */
  display: flex;
  align-items: center;
  /* padding: 1px; */
  color: #fff;
  cursor: pointer;
}
.log-in-card__google-button:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.log-in-card__google-button:active {
  background: #337ab7;
}
.log-in-card__google-button-logo {
  height: 43px;
}
.log-in-card__google-button-text {
  text-align: left;
  white-space: nowrap;
  padding: 10px;
}
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.not-found__description-title.ant-typography {
  display: block;
  padding-bottom: 10px;
  color: #1d226c;
  font-size: 22px;
  font-weight: 700;
}
.not-found__description-subtitle.ant-typography {
  color: #1d226c;
  font-size: 16px;
}
.not-found__cta {
  margin-top: 20px;
}
.stripe {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-bookings {
  padding-top: 40px;
}
.user-bookings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.user-bookings__description.ant-typography {
  font-size: 15px;
}
.user-bookings .ant-list-pagination {
  padding-bottom: 20px;
}
.user-bookings__booking-item {
  margin: 20px 0;
}
.user-bookings__booking-history {
  margin-bottom: 20px;
}
.user-bookings__booking-history > div {
  font-size: 13px;
  margin-bottom: 5px;
}
.user-bookings__booking-stars {
  font-size: 13px;
}
.user-listings {
  padding-top: 40px;
}
.user-listings__title.ant-typography {
  color: #1d226c;
  display: inline-block;
}
.user-listings .ant-list-pagination {
  padding-bottom: 20px;
}
.user-listings__description.ant-typography {
  font-size: 15px;
}
.user-profile {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}
.user-profile__card {
  width: 100%;
}
.user-profile__avatar {
  text-align: center;
}
.user-profile__details h4.ant-typography {
  color: #1d226c;
}
.user-profile__details div.ant-typography {
  font-size: 15px;
}
.user-profile__details-cta {
  margin-bottom: 20px;
}
.user {
  padding: 60px 120px;
}
@media (max-width: 75em) {
  .user {
    padding-left: 20px;
    padding-right: 20px;
  }
}
html {
  overflow: auto;
}
#root,
body,
html {
  height: 100%;
  width: 100%;
}
#app {
  position: relative;
  background: #fff;
  min-height: 100vh;
}
.app-skeleton {
  height: 100%;
  background: #fff;
  display: flex;
}
.app-skeleton__spin-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.app__affix-header {
  z-index: 99;
}
.app__affix-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
@media (max-width: 36em) {
  .app__affix-footer {
    display: none;
  }
}

.event-details__information {
  color: #808080;
}

.site-layout-content {
  min-height: 280px;
  padding: 0px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.placeholder {
  background-color: #f0f0f0;
  color: #bbb;
  text-align: center;
  height: 30px;
  line-height: 30px;
  width: 100%;
}

.user-events_header {
  display: flex;
  justify-content: space-between;
}

.file-upload_button {
  width: 88px;
  height: 44px;

  background: #337ab7;
  border: 1px solid #2e6da4;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #ffffff;
}

.file-upload_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload_modal_backdrop {
  width: 100%;
  height: 100%;
  background: #c5c5c59a;
}

.file-upload_modal_box {
  position: absolute;
  width: 350px;
  /* height: 340px; */

  padding: 25px 20px;

  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
}

.file-upload_modal_box div {
  text-align: right;
  color: #000000;
  font-size: 20px;
}

.file-upload_modal_box p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  color: #3b3b3b;
}

.file-upload_modal_box span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height, or 142% */

  color: #3b3b3b;
}

.file-upload_error {
  color: #b73333 !important;
}

.file-upload_modal_box button {
  width: 100%;
  height: 40px;

  background: #337ab7;
  border: 1px solid #2e6da4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 30px;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  color: #ffffff;
}

.file-upload_modal_box button:disabled {
  background-color: gray;
}

.file-upload_dropzone {
  width: 320px;
  height: 96px;

  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.file-upload_dropzone p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  text-align: center;

  color: #3b3b3b;
}

.FileList {
  display: flex;
}

.FileList span {
  margin-left: 10px;
}

.FileList svg path {
  fill: red;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.RA-months_list {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  margin: 10px 0;
}

.RA-month_box {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.RA-month_header {
  background-color: #e7e7e7;
  height: 30px;

  border-right: 1px solid #f0f0f0;
  color: #3b3b3b;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.RA-month_title {
  height: 30px;
  border-right: 1px solid #f0f0f0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

#yearSelectLabel {
  width: 68px;
  height: 14px;

  color: #8c8c8c;
}

#yearSelect {
  width: 115px;
  height: 26px;

  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 10px;
}

.RA-Header_nav {
  border-bottom: 1px solid #f0f0f0;
  margin: 10px 0;
  width: 400px;
  display: flex;
  justify-content: space-between;
}

.RA-Header_nav span {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;

  color: #8c8c8c;

  cursor: pointer;
}

.RA-Header_active {
  color: #000000d9 !important;
  border-bottom: 1px solid #337ab7;
}

.header-active {
  color: #337ab7 !important;
  font-weight: 700;
}

/* TOAST */

.globalToast {
  width: 100%;
  min-height: 54px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 12px 24px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  -webkit-animation: slideDown 3s;
          animation: slideDown 3s;

  z-index: 10000;
}

.globalToast p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.globalToast.warning {
  background-color: #feefd0;
}

.globalToast.error {
  background-color: #eac6c7;
}

.globalToast.success {
  background-color: #c2d7ba;
}

.toastCloseIcon {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

@-webkit-keyframes slideDown {
  0%,
  100% {
    -webkit-transform: translateY(-100px);
  }
  10%,
  90% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes slideDown {
  0%,
  100% {
    -webkit-transform: translateY(-100px);
  }
  10%,
  90% {
    -webkit-transform: translateY(0px);
  }
}
/* /TOAST */

.page-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;

  color: #3b3b3b;
}

.page-small-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #3b3b3b;
}

.page-description {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;

  color: #808080;
}

.mihin-form {
  display: flex;
  flex-direction: column;
}

.mihin-form label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #3b3b3b;
  margin-top: 10px;
}

.mihin-form input {
  width: 322px;
  height: 44px;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 2px;
}

.mihin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background: #337ab7;
  border-radius: 4px;

  border: 0;
  color: #fff;

  margin-top: 20px;
  cursor: pointer;
}

.mihin-button-row {
  display: flex;
  align-items: center;
}

.mihin-response {
  margin-top: 20px;
  margin-left: 20px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
}

.mihin-response.error {
  color: #ff4d4f;
}

.mihin-response.error svg path {
  fill: #ff4d4f;
}

.mihin-response.success {
  color: #52992e;
}

.mihin-response.success svg path {
  fill: #52992e;
}

.container {
  padding: 40px 110px;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;

  color: #3b3b3b;
}

.exportuploadbuttonposition {
  position: absolute;
  right: 110px;
  top: 100px;

  display: flex;
}

.export-button {
  padding: 12px 24px;

  width: 94px;
  height: 44px;

  background: #337ab7;
  border-radius: 4px;

  border: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #ffffff;
  margin-left: 10px;
  cursor: pointer;
}

.clear {
  background: white;
  border: 1px solid #3b3b3b;
  color: #3b3b3b;
}

.export-button:hover {
  color: #ffffff;
}

.clear:hover {
  color: #3b3b3b;
}

.content-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.content-menu ul li {
  float: left;
}

.content-menu ul li {
  padding: 16px;
  text-decoration: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  color: #3b3b3b;

  box-shadow: inset 0px -2px 0px #e6e6e6;
  cursor: pointer;
}

.content-menu-active {
  box-shadow: inset 0px -2px 0px #337ab7 !important;
  font-weight: 700 !important;
  color: #337ab7 !important;
}

#content-table {
  /* border-collapse: collapse; */
  width: 100%;
  margin-top: 20px;
}

#content-table td,
#content-table th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #3b3b3b;

  max-width: 350px;
}

/* #content-table tr:nth-child(even) {
  background-color: #f2f2f2;
} */

#content-table tr:hover {
  background-color: #ddd;
}

#content-table th {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 24px;
  background-color: #e7e7e7;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  color: #3b3b3b;
}

#content-table th.up {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==)
}

#content-table th.down {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=)
}

#content-table th.default {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABFElEQVQ4ja3UPyuGYRQG8N8jReod7EYWX8FCZ7GSxWhQoizKxEQ2JWUy8AHEbDix+AomNrvB4k95DR6Gp9vDq/eqU3fXOee6r9N9uqtut6tfGOibEgZLZFVV3+fMHMEeNiPi9YsvTfQXZ2tYxfJvhVXphi9nmTmJS4zhDrMRcd+zs8yssFELwTjWa76ItjGnMdfgljDVk1hmdrCN0Uaqg63MHOrF2ULt4LkQM5gvNbU+QBtKfcU9y8xFnOK9kB7ACk5KiRLOcYXhQtzgrNRUFIuIF+ziqZF6xE5ENPlWZ2oHzVEucP1Tw49iEdHFoc/Nhwfs13xvYrXgPQ7whsOIuG2rL75mA8eYwNFvhcU9+y/6+jl+AEHEUkWcZayjAAAAAElFTkSuQmCC)
}

th.up,
th.default,
th.down {
  background-repeat: no-repeat;
  background-position: center right;
}

.content-table-mobile {
  display: flex;
  flex-direction: column;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  /* display: flex;
  align-items: center; */

  color: #3b3b3b;

  display: none;

  margin-top: 20px;
}

.TableSuccess {
  color: #52992e !important;
}
.TableFail {
  color: #ff4d4f !important;
}

.TM-table-box {
  margin-top: 20px;
  border: 1px solid #e7e7e7;
}

.TM-content {
  height: 40px;
  padding: 0 8px;
}

.TM-content:nth-child(even) {
  background: #fafafa;
}

.TM-header {
  height: 40px;
  background: #e7e7e7;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.TM-header-content {
  height: 40px;
  font-weight: 700;
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.TM-content-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #3b3b3b;
}

.TM-content-data {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #808080;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* margin-left: 20px; */
}

.pagination ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.pagination ul li {
  /* Auto layout */

  /* padding: 4px; */

  /* flex: none;
  order: 1;
  flex-grow: 0; */
  margin: 0px 4px;
  border-radius: 8px;
}

.pagination ul li a,
.paginateButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  box-sizing: border-box;
  border-radius: 8px;

  cursor: pointer;

  color: #808080;
}

.pagination ul li.selected a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  color: #337ab7;
}

.pagination ul li.next,
.pagination ul li.previous,
.paginateButton {
  border: 1px solid #c5c5c5;
}

.search-filter-box {
  padding: 8px 8px 8px 24px;

  height: 60px;

  background: #fafafa;
  border-radius: 9px;

  margin: 32px 0px;
}

.search-filter-label {
  width: 175px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #3b3b3b;

  margin: 0px 16px;
}

.search-filter-input {
  padding: 7px 8px 7px 12px;

  width: 103px;
  height: 32px;

  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 4px;

  margin: 0px 16px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: #c5c5c5 !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  /* -webkit-box-shadow: 0 0 0 3px rgb(52 152 255 / 25%); */
  /* -webkit-box-shadow: var(--rs-state-focus-shadow); */
  /* box-shadow: 0 0 0 3px rgb(52 152 255 / 25%); */
  /* box-shadow: var(--rs-state-focus-shadow); */
  box-shadow: none !important;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

@media screen and (min-width: 732px) {
  .RA-month_box {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .RA-months_list {
    flex-direction: column;
  }

  .RA-month_box {
    width: 100%;
  }

  .RA-Header_nav {
    width: 100%;
  }
  .RA-Header_nav span {
    font-size: 13px;
  }

  .container {
    padding: 40px 30px;
  }

  .mihin-form input,
  .mihin-button {
    width: 100%;
  }

  .mihin-button-row {
    flex-direction: column;
    width: 100%;
  }

  .app-header-links {
    display: none;
  }

  .app-header_hamburger_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 50px; */
    cursor: pointer;
    margin-left: 27px;
  }

  .app-header__logo img {
    width: 100px;
  }

  #content-table {
    display: none;
  }
  .content-table-mobile {
    display: block;
  }

  .export-button {
    right: 30px;
  }

  .content-menu ul li {
    padding: 13px;
  }

  .exportuploadbuttonposition {
    right: 5px;
  }

  .search-filter-box {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }

  .search-filter-box > div.flex {
    flex-direction: column;
  }

  .search-filter-box > div.flex > div {
    display: flex;
    flex-direction: column;
  }

  .search-filter-box > div.flex > div > input,
  .search-filter-box > div.flex > div > select {
    width: 110%;
  }

  .searchButtons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
}

